import "../auxillary.scss"
import React from "react"
import { FooterContent } from "../../Footer/Footer"

function CookiesPolicy() {
  return (
    <>
      <section className="auxillary-wrapper">
        <div className="auxillary cookies-policy">
          <div className="auxillary__inner">
            <h1>Cookies Policy</h1>
            <div className="auxillary__content-column">
              <p>
                By continuing to use our site you agree to our use of cookies.
                Please see our
                <a href="/privacy-policy" target="_blank" rel="noreferrer">
                  {" "}
                  Privacy Policy{" "}
                </a>
                and
                <a href="/terms-of-service" target="_blank" rel="noreferrer">
                  {" "}
                  Terms of Use{" "}
                </a>
                for more information.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div>
        <FooterContent selected />
      </div>
    </>
  )
}

export default CookiesPolicy
